<template>
  <div class="observation">
    <div class="title">
      {{ title }}
      <van-icon @click="closePop" name="cross" />
    </div>
    <van-tree-select
      v-if="items"
      height="235px"
      :items="items"
      @click-nav="clickItems"
      @click-item="clickItem"
      :active-id.sync="activeId"
      :main-active-index.sync="itemsIndex"
      slot="content"
    >
      <van-tree-select
        v-if="items[itemsIndex]"
        height="235px"
        :items="items[itemsIndex].children"
        @click-nav="clickItemsChild"
        :active-id.sync="activeId"
        :main-active-index.sync="itemsChildIndex"
        slot="content"
      >
      </van-tree-select>
    </van-tree-select>
    <van-overlay
      :show="addLoading"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <van-loading
        style="
          position: absolute;
          top: calc(50% - 25px);
          left: calc(50% - 30px);
        "
        size="50px"
        color="#0094ff"
        vertical
        text-color="#0094ff"
        >加载中</van-loading
      >
    </van-overlay>
  </div>
</template>
<script>
import { Overlay, Loading, TreeSelect, Icon } from "vant";
export default {
  name: "citySelect", // 城市选择列表
  components: {
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [TreeSelect.name]: TreeSelect,
    [Icon.name]: Icon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      typeof: Array,
      default: {},
    },
    currentPosRecords: {
      typeof: Object.JSON,
      default: {},
    },
  },
  data() {
    return {
      close: true,
      activeId: null,
      itemsIndex: 0,
      itemsChildIndex: -1,
      addLoading: false,
      itemExtend: [],
    };
  },
  watch: {
    items() {
      // console.log(1111, this.items);
      // this.clickItems();
    },
    currentPosRecords(val) {
      if (val) {
        this.itemsIndex = val.itemsIndex;
        this.itemsChildIndex = val.itemsChildIndex;
      }
    },
  },
  mounted() {},
  methods: {
    closePop() {
      this.$emit("close");
    },
    clickItems() {
      // this.itemsChildIndex = -1;
      this.check(
        Object.assign(
          {
            type: "cs0",
            itemsIndex: this.itemsIndex,
            itemsChildIndex: this.itemsChildIndex,
          },
          this.items[this.itemsIndex]
        )
      );
    },
    clickItemsChild() {
      this.check(
        Object.assign(
          {
            type: "cs1",
            itemsIndex: this.itemsIndex,
            itemsChildIndex: this.itemsChildIndex,
          },
          this.items[this.itemsIndex].children[this.itemsChildIndex]
        )
      );
    },
    clickItem(row) {
      this.check(
        Object.assign(
          {
            type: "cs2",
            itemsIndex: this.itemsIndex,
            itemsChildIndex: this.itemsChildIndex,
          },
          row
        )
      );
    },
    check(row) {
      this.$emit("check", row);
    },
  },
};
</script>
<style lang="scss" scoped>
.observation {
  position: fixed;
  width: 100%;
  left: 0;
  height: 270px;
  border-radius: 10px;
  bottom: 0;
  z-index: 98;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
    font-size: 0.9rem;
    padding: 0 1rem;
    justify-content: space-between;
    color: white;
    background: #148aef;
  }
}

/deep/ .van-tree-select {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/deep/ .van-tree-select__nav {
  border-bottom-left-radius: 10px;
}
/deep/ .van-tree-select__content {
  border-bottom-right-radius: 10px;
}
/deep/ .van-tree-select__item--active {
  color: inherit;
}
/deep/ .van-tree-select__selected {
  display: none;
}
</style>
