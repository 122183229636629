<template>
  <div class="citySeaPageContainer">
    <map-base ref="mapCom" :map="map" />
  </div>
</template>

<script>
// <script module = 'citySeaPageContainer' lang="renderjs">
export default {
  name: "citySeaPage",
  components: {},
  props: ["map"],
  data() {
    return {
      dataConfig: this.$store.getters.mapNorthForeCast.stations[0],
    };
  },
  inject: [],
  watch: {},
  onLoad() {},
  mounted() {
    this.layerId = this.dataConfig.layerId;
    this.forecastType = this.dataConfig.forecastType;
    this.title = this.dataConfig.title;
  },
  methods: {
    singleClick(evt) {
      let array = [];
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer && layer.get("id").indexOf(this.layerId) > -1) {
          array.push([feature, layer.get("id")]);
        }
        if (array.length === 1) {
          this.$refs.mapCom.removeLightMarkerLayer();
          let feature = array[0][0];
          this.$store.dispatch("mapNorthForeCast/getData", {
            area: feature.get("name"),
            beginDate: this.$getTimeFormat(new Date().getTime(), "yyyy-mm-dd"),
            element: "",
            ageing: "",
            checked: true,
          });
          // let lon, lat, showData;
          // lon = feature.getGeometry().getCoordinates()[0];
          // lat = feature.getGeometry().getCoordinates()[1];
          // showData = {
          //   名称: feature.get("name"),
          //   经度: feature.get("longitude"),
          //   纬度: feature.get("latitude"),
          //   dragId: feature.get("uniqueId"),
          // };
          // if (!showData) return;
          // //  信息弹窗
          // this.$refs.mapCom.initInfoWinContainer(
          //   [lon, lat],
          //   [showData],
          //   this.title,
          //   [
          //     {
          //       show: true,
          //       name: "查看信息",
          //       type: "click",
          //       fun: () => {
          //         this.$store.dispatch("mapNorthForeCast/getData", {
          //           area: showData["名称"],
          //           beginDate: this.$getTimeFormat(
          //             new Date().getTime(),
          //             "yyyy-mm-dd"
          //           ),
          //           element: "",
          //           ageing: "",
          //           checked: true,
          //         });
          //         // this.$message.success(feature.get("name") + "查看信息成功");
          //       },
          //     },
          //   ],
          //   () => {},
          //   this.layerId
          // );
        }
      });
    },
    async addData(param) {
      this.removeLayer();
      let data = param.data ? param.data : await this.getData();
      const layer = this.addLayer();
      if (data.length > 0) {
        data.map((v) => {
          this.$refs.mapCom.getNormalUniqueId(v, this.layerId);
          // v.longitude = v.position[0];
          // v.latitude = v.position[1];
          v.longitude = v.lon;
          v.latitude = v.lat;
        });
        this.$refs.mapCom.fitExtent(data, (features) => {
          features.map((v) => {
            const imgScale = 0.5;
            const textScale = 1.5;
            const offsetX = 0;
            const offsetY = 30;
            v.setStyle(
              new this.mapApi.Style({
                image: new this.mapApi.Icon({
                  src: param.image[0],
                  scale: imgScale,
                }),
                text: new this.mapApi.Text({
                  font: "12px sans-serif",
                  offsetX: offsetX,
                  offsetY: offsetY,
                  textAlign: "center",
                  scale: 0,
                  text: v.get("name"),
                  fill: new this.mapApi.Fill({ color: "blue" }),
                  textBaseline: "bottom",
                  stroke: new this.mapApi.Stroke({
                    // 线样式
                    color: "#fff",
                    width: 1,
                  }),
                }),
              })
            );
            this.map.on("moveend", () => {
              const style = v.getStyle();
              let img = style.getImage();
              let text = style.getText();
              let zoom = this.map.getView().getZoom();
              if (zoom <= 7) {
                text.setScale(0);
                // img.setScale((imgScale * zoom) / 10);
              } else {
                text.setOffsetY(offsetY + (zoom - 10) * 5);
                text.setScale((textScale * zoom) / 10);
                // img.setScale(0);
              }
              img.setScale((imgScale * zoom) / 10);
              v.setStyle(style);
            });
          });
          layer.getSource().addFeatures(features);
        });
      }
    },
    async getData() {
      let data = await this.$store.dispatch("mapNorthForeCast/getStations", {
        forecastType: this.forecastType,
      });
      if (data && data.success) data = data.result;
      else data = require("./data/citySea.json");
      return data;
    },
    addLayer() {
      let layer = this.$refs.mapCom.createLayer(this.layerId);
      this.map.addLayer(layer);
      return layer;
    },
    removeLayer() {
      if (this.$refs.mapCom.getLayer(this.layerId, 1))
        this.$refs.mapCom.removeLayer(this.layerId, 1);
      this.$refs.mapCom.closeInfoWin(null, this.layerId);
    },
  },
  beforeDestroy() {
    this.removeLayer();
  },
};
</script>

<style scoped></style>
