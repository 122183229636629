<template>
  <div v-if="isShow">
    <div v-if="dataType === dataTypes[1]" class="table-forecast">
      <div class="title">
        <div class="name">{{ queryData.area }}</div>
        <div class="time" style="margin-top:30px">
          {{ queryData.beginDate }}~{{ queryData.endDate }}
        </div>
        <!-- <div class="close" @click="isShow = false">X</div> -->
      </div>
      <div class="content">
        <div class="th">
          <span
            class="c-title"
            v-for="(item, index) in dataArray"
            :key="index"
            >{{ item.name }}</span
          >
        </div>
        <div v-if="dataCount" class="td">
          <div class="col_3" v-for="(item, index) in dataArray" :key="index">
            <div v-if="item.value.length > 0">
              <div v-for="(item, index) in item.value" :key="index">
                <span>{{ item.data }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>逐小时预报数据</div>
      </div>
    </div>
    <div v-if="dataType === dataTypes[0]" class="table-forecast">
      <div class="title">
        <div class="name">{{ queryData.area }}</div>
        <div class="time" style="margin-top:30px">
          {{ queryData.beginDate }}~{{ queryData.endDate }}
        </div>
        <!-- <div class="close" @click="isShow = false">X</div> -->
      </div>
      <div class="content">
        <div class="th">
          <span
            class="c-title"
            v-for="(item, index) in dataArray"
            :key="index"
            >{{ item.name }}</span
          >
        </div>
        <div v-if="dataCount" class="content-tide">
          <div class="td">
            <div class="col_3" v-for="(item, index) in dataArray" :key="index">
              <div v-if="item.value.length > 0">
                <div v-for="(item, index) in item.value" :key="index">
                  <span>{{ item.data }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="tf">
            <div
              class="col_2"
              v-for="(item, index) in dataArrayOther"
              :key="index"
            >
              <div v-if="item.value.length > 0">
                <div
                  class="item-c"
                  v-for="(itemC, index) in item.value"
                  :key="index"
                >
                  <img :src="item.image" />
                  <span style="color: #1d62ff">{{ item.name }}:</span>
                  <span>{{ itemC.data }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>逐小时预报数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "table-forecast",
  components: {},
  props: [],
  data() {
    return {
      isShow: false,
      queryData: {},
      waveHeight_time: [],
      items: this.$store.getters.mapNorthForeCast.foreCastItems,
      dataArray: {}, // 预报数据
      dataArrayOther: {}, // 潮汐数据存在时其他预报项
      dataCount: 0, // 预报数据存在判断
      timeKey: "time", // 时效key
      tideHightNewKey: "tideHightNew", // 潮汐key
      tideHightTimeKey: "tideHightTime", // 潮时key
      tideHightLevelKey: "tideHightLevel", // 潮位key
      dataTypes: ["tideHeight", "other"],
      dataType: "",
    };
  },
  inject: [],
  watch: {},
  onLoad() {},
  mounted() {},
  methods: {
    refreshData(param) {
      this.isShow = false;
      this.$nextTick(async () => {
        this.isShow = param.checked;
        if (this.isShow) {
          this.queryData = Object.assign({}, param);
          this.queryData.endDate = this.$getTimeFormat(
            Number(new Date(this.queryData.beginDate).getTime()) +
              Number(this.queryData.ageing) * 24 * 60 * 60,
            "yyyy-mm-dd"
          );
          let data = await this.$store.dispatch(
            "mapNorthForeCast/getForecastData",
            this.queryData
          );
          if (data.success) {
            data = data.result;
            this.dataArray = {};
            this.dataArrayOther = {};
            this.dealData(data);
          }
        }
      });
    },
    dealData(data) {
      if (data[this.tideHightNewKey]) {
        this.dataType = this.dataTypes[0];
        this.dealTideHight(data);
      } else {
        this.dataType = this.dataTypes[1];
        this.dealOther(data);
      }
      this.tableJudge();
      // console.log("预报数据", this.dataArray, this.dataArrayOther);
    },
    // 潮汐数据处理
    dealTideHight(data) {
      // 0 潮汐；1：其他
      const detailDeal = (type) => {
        const fun = (data, key, key1, key2) => {
          if (data && data.forecastDataDTOList) {
            this.dataArray[key] = { value: [], name: this.items[key].title };
            this.dataArray[key1] = { value: [], name: this.items[key1].title };
            this.dataArray[key2] = { value: [], name: this.items[key2].title };
            let dataValue = data.forecastDataDTOList;
            dataValue.map((v) => {
              let tideMap = v.tideMap;
              if (tideMap) {
                for (let i in tideMap) {
                  this.dataArray[key].value.push({
                    data: i.split("_")[0],
                  });
                  this.dataArray[key1].value.push({
                    data: i.split("_")[1],
                  });
                  this.dataArray[key2].value.push({
                    data: tideMap[i],
                  });
                }
              }
            });
          }
        };
        const otherFun = (data, key) => {
          if (data && data.forecastDataDTOList) {
            this.dataArrayOther[key] = {
              value: [],
              name: this.items[key].title,
              image: this.items[key].image,
            };
            let dataValue = data.forecastDataDTOList;
            dataValue.map((v) => {
              let information = v.information;
              if (information) {
                for (let i in information) {
                  // 取第一条数据（24h）
                  if (this.dataArrayOther[key].value.length === 0)
                    this.dataArrayOther[key].value.push({
                      data: information[i],
                    });
                }
              }
            });
          }
        };
        switch (type) {
          case 0:
            // 时效,潮时,潮位
            fun(
              data[this.tideHightNewKey],
              this.timeKey,
              this.tideHightTimeKey,
              this.tideHightLevelKey
            );
            break;
          case 1:
            // 其他预报数据
            for (let key in data) {
              if (key !== "area" && key !== this.tideHightNewKey) {
                otherFun(data[key], key);
              }
            }
            break;
          default:
            // 时效,潮时,潮位
            fun(
              data[this.tideHightNewKey],
              this.timeKey,
              this.tideHightTimeKey,
              this.tideHightLevelKey
            );
            break;
        }
      };
      detailDeal(0);
      detailDeal(1);
    },
    // 其他数据处理
    dealOther(data) {
      // 时效处理
      const timeDeal = () => {
        // 获取预报项的预报时间
        const fun = (data) => {
          if (data && data.forecastDataDTOList) {
            let dataValue = data.forecastDataDTOList;
            dataValue.map((v) => {
              let information = v.information;
              if (information) {
                for (let i in information) {
                  if (i === "semidiurnal") i = "12h";
                  else if (i === "oneDay") i = "24h";
                  else if (i === "twoDays") i = "48h";
                  else if (i === "threeDays") i = "72h";
                  times.push(Number(i.split("h")[0]));
                }
              }
            });
          }
        };
        // 排序过滤
        const filter = (times) => {
          let json = {};
          let waveHeight_time = [];
          times.sort();
          times.map((v) => {
            if (!json[v]) {
              waveHeight_time.push({
                time: v,
              });
              json[v] = true;
            }
          });
          return waveHeight_time;
        };
        // 预报项时间
        let times = [];
        for (let key in data) {
          if (key !== "area") {
            fun(data[key]);
          }
        }
        // 时效处理
        this.dataArray[this.timeKey] = {
          value: [],
          name: this.items[this.timeKey].title,
        };
        filter(times).map((v) => {
          this.dataArray[this.timeKey].value.push({
            data: v.time + "h",
          });
        });
      };
      // 预报数据处理
      const detailDeal = () => {
        const fun = (data, key) => {
          if (data && data.forecastDataDTOList) {
            this.dataArray[key] = { value: [], name: this.items[key].title };
            // 根据预报日期设置默认数据
            this.dataArray[this.timeKey].value.map((v) => {
              this.dataArray[key].value.push({
                time: v.data,
                data: "-",
              });
            });
            let dataValue = data.forecastDataDTOList;
            dataValue.map((v) => {
              let information = v.information;
              if (information) {
                for (let i in information) {
                  let a = ''
                  if (i === "semidiurnal") a = "12h";
                  else if (i === "oneDay") a = "24h";
                  else if (i === "twoDays") a = "48h";
                  else if (i === "threeDays") a = "72h";
                  // 根据预报日期设置日期对应数据
                  this.dataArray[key].value.map((v) => {
                    if (a === v.time) v.data = information[i];
                  });
                }
              }
            });
          }
        };
        for (let key in data) {
          if (key !== "area") {
            fun(data[key], key);
          }
        }
      };
      timeDeal();
      detailDeal();
    },
    tableJudge() {
      this.dataCount = 0;
      for (let i in this.dataArray) {
        if (this.dataArray[i].value.length !== 0) this.dataCount++;
      }
    },
  },
  beforeDestroy() {
    this.removeLayer();
  },
};
</script>

<style lang="scss" scoped>
.table-forecast {
  position: fixed;
  z-index: 3;
  background-color: white;
  transform: translateX(-30%);
  margin-left: 30%;
  margin-top: 30%;
  width: 300px;
  // height: 200px;
  // min-height: 150px;
  border: 1px solid rgba(29, 98, 255, 0.59);
  box-shadow: -1px -1px 8px 0px rgba(29, 98, 255, 0.59);
  border-radius: 5px;
  overflow: hidden;
  font-size: 15px;
  .title {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px dashed rgba(29, 98, 255, 0.3);
    height: 30px;
    .name {
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 800;
      text-align: left;
      color: #333333;
      max-width: 95%;
    }
    .time {
      position: fixed;
      right: 10px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      margin-left: 15px;
    }
    .close {
      position: fixed;
      right: 10px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 130px;
    padding: 10px 0;
    overflow: hidden;
    overflow-x: scroll;
    // overflow-y: scroll;
    align-items: center;
    // justify-content: center;
    .th {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      text-align: center;
      align-items: center;
      .c-title {
        display: inline-block;
        white-space: nowrap;
        color: #1d62ff;
        min-width: calc(100% / 3);
        text-align: center;
      }
    }
    .content-tide {
      width: 100%;
      // height: 130%;
      text-align: center;
    }
    .td {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      // height: 130%;
      width: 100%;
      margin-top: 7px;
    }
    .tf {
      align-items: center;
      flex-wrap: nowrap;
      // height: 130%;
      width: 100%;
      margin-top: 10px;
      display: flex;
    }

    .col_3 {
      min-width: calc(100% / 3);
      height: 100%;
      line-height: 10px;
      align-items: center;
      text-align: center;
      line-height: 26px;
    }
    .col_2 {
      min-width: calc(100% / 2);
      height: 100%;
      line-height: 10px;
      align-items: center;
      text-align: center;
      line-height: 30px;

      .item-c {
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
</style>
