<template>
  <div class="cityCurrent" @click="changePos">
    <span class="mark"><img :src="imgSrc[0]" alt="" /></span>
    <span>{{ cityCurrentData.name }}</span>
    <span class="mark posTo"><img :src="imgSrc[1]" alt="" /></span>
  </div>
</template>

<script>
export default {
  name: "cityCurrent", // 城市选择标签
  components: {},
  data() {
    return {
      imgSrc: [
        require("@/assets/image/map/pos.png"),
        require("@/assets/image/map/posTo.png"),
      ],
    };
  },
  props: {
    cityCurrentData: {
      type: Object.JSON,
      default: {},
    },
  },
  watch: {
    // cityCurrentData(val) {
    //   console.log("cityCurrentData", val);
    // },
  },
  onLoad() {},
  mounted() {},
  methods: {
    changePos() {
      this.$emit("changePos", this.cityCurrentData);
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.cityCurrent {
  position: fixed;
  z-index: 99;
  left: 10px;
  top: 10px;
  width: auto;
  display: flex;
  align-items: center;
  span {
    margin-right: 5px;
  }
  .mark {
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .posTo {
    width: 15px;
    height: 15px;
  }
}
</style>
