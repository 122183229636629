<template>
  <div class="citySelect">
    <van-field
      readonly
      clickable
      label="类型"
      :value="typeName"
      placeholder="选择网格化类型"
      @click="typeSelect"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="名称"
        show-toolbar
        :columns="projectListArr"
        @cancel="showPicker = false"
        @confirm="changeConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { Field, Picker, Popup } from "vant";
export default {
  name: "citySelect_grid", //网格化类型选择
  components: {
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  data() {
    return {
      typeName: "", //input框内显示的值
      projectListArr: ["近陆", "近海"],
      showPicker: false, //弹出层显示隐藏
    };
  },
  watch: {},
  onLoad() {},
  mounted() {
    let checkedIndex = 0;
    this.changeConfirm(this.projectListArr[checkedIndex], checkedIndex);
  },
  methods: {
    typeSelect() {
      this.showPicker = true;
    },
    changeConfirm(val, index) {
      this.typeName = val; //传值
      this.showPicker = false;
      this.$emit("gridSelect", {
        type: this.projectListArr[index],
        gridShow: index === 0 ? true : false,
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.citySelect {
  position: fixed;
  z-index: 99;
  left: 10px;
  top: 40px;
  width: 70%;
}
</style>
