<template>
  <div class="gridPageContainer">
    <map-base ref="mapCom" :map="map" />
  </div>
</template>

<script>
// <script module = 'gridPageContainer' lang="renderjs">
export default {
  name: "gridPage",
  components: {},
  props: ["map"],
  data() {
    return {
      dataConfig: this.$store.getters.mapNorthForeCast.stations[2],
    };
  },
  inject: [],
  watch: {},
  onLoad() {},
  mounted() {
    this.layerId = this.dataConfig.layerId;
    this.forecastType = this.dataConfig.forecastType;
    this.title = this.dataConfig.title;
  },
  methods: {
    singleClick(evt) {
      let array = [];
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer && layer.get("id").indexOf(this.layerId) > -1) {
          array.push([feature, layer.get("id")]);
        }
        if (array.length === 1) {
          this.$refs.mapCom.removeLightMarkerLayer();
          let feature = array[0][0];
          this.$store.dispatch("mapNorthForeCast/getData", {
            area: feature.get("name"),
            beginDate: this.$getTimeFormat(new Date().getTime(), "yyyy-mm-dd"),
            element: "",
            ageing: "",
            checked: true,
          });
        }
      });
    },
    addData() {
      let layer = this.$refs.mapCom.createLayer(this.layerId);
      this.$refs.mapCom.insertAtLayer(
        parseInt(this.$refs.mapCom.getbaseMapLayer().length) - 1,
        layer
      );
      let data = require("./data/grid.json");
      let features = new this.mapApi.GeoJSON().readFeatures(data);
      layer.getSource().addFeatures(features);
      features.map((v) => {
        let json = {
          name: v.get("地名"),
          code: v.get("编号"),
        };
        v.setProperties(json);
        v.setStyle(
          new this.mapApi.Style({
            fill: new this.mapApi.Fill({
              color: "rgba(134,223,139)",
            }),
            // text: new this.mapApi.Text({
            //   font: "20",
            //   offsetX: 0,
            //   offsetY: 30,
            //   scale: 2,
            //   text: v.get("code"),
            //   fill: new this.mapApi.Fill({ color: "rgba(255,0,0)" }),
            //   textBaseline: "bottom",
            // }),
            stroke: new this.mapApi.Stroke({
              color: "#000",
              width: 1,
              // lineDash: [1, 3, 5],
            }),
          })
        );
      });
      const addName = () => {
        if (features.length > 0) {
          let data = [];
          features.map((v) => {
            let coord = this.mapApi.getCenter(v.getGeometry().getExtent());
            data.push({
              longitude: coord[0],
              latitude: coord[1],
              name: v.get("name"),
              code: v.get("code"),
            });
          });
          this.$refs.mapCom.fitExtent(data, (features) => {
            features.map((v) => {
              v.setStyle(
                new this.mapApi.Style({
                  image: new this.mapApi.Circle({
                    radius: 1,
                  }),
                  text: new this.mapApi.Text({
                    font: "20",
                    offsetX: 0,
                    offsetY: 0,
                    textAlign: "center",
                    scale: 1.5,
                    text: v.get("code"),
                    fill: new this.mapApi.Fill({ color: "rgba(255,0,0)" }),
                    // textBaseline: "bottom",
                    stroke: new this.mapApi.Stroke({
                      // 线样式
                      color: "rgba(255,0,0)",
                      width: 1,
                    }),
                  }),
                })
              );
            });
            layer.getSource().addFeatures(features);
          });
        }
      };
      addName();
    },
    async getData() {
      let data = await this.$store.dispatch("mapNorthForeCast/getStations", {
        forecastType: this.forecastType,
      });
      if (data && data.success) data = data.result;
      else data = require("./data/grid.json");
      return data;
    },
    addLayer() {
      let layer = this.$refs.mapCom.createLayer(this.layerId);
      this.map.addLayer(layer);
      return layer;
    },
    removeLayer() {
      if (this.$refs.mapCom.getLayer(this.layerId, 1))
        this.$refs.mapCom.removeLayer(this.layerId, 1);
      this.$refs.mapCom.closeInfoWin(null, this.layerId);
    },
  },
  beforeDestroy() {
    this.removeLayer();
  },
};
</script>

<style scoped></style>
