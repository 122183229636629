<template>
  <div class="appMenu">
    <map-view ref="mapView" />
    <div class="context" v-if="contextShow">
      <div v-for="(item, index) in context" :key="index">
        <img
          :src="context_index === index ? item.image_select : item.image"
          class="contextImage"
          @click="contextFun(item, index)"
        />
      </div>
    </div>
    <div v-if="!contextShow" @click="showContext($event)">
      <div class="context_more_hidding"></div>
      <div class="context_more">
        <div
          v-for="(item, index) in context_more"
          :key="index"
          @click="controlSwitch($event)"
        >
          <div class="system-row">
            <div class="img">
              <img :src="item.image[0]" alt="" />
            </div>
            <div class="font">
              <font>{{ item.title }}</font>
            </div>
            <el-switch
              class="switch"
              v-model="item.checked"
              size="18px"
              @change="
                (checked) => handeSwitch(item, checked, item.title, item.image)
              "
              :disabled="item.disabled"
            />
          </div>
        </div>
      </div>
    </div>
    <TableForcast ref="tableForcast" />
  </div>
</template>
<script>
import MapView from "@/views/map/index.vue";
import TableForcast from "@/views/map/mapNorthForeCast/table/index.vue";
export default {
  name: "AppMenu",
  components: {
    MapView,
    TableForcast,
  },
  computed: {},
  data() {
    return {
      mapNorthForeCast: this.$store.getters.mapNorthForeCast,
      context: this.$store.getters.mapNorthForeCast.context,
      context_more: this.$store.getters.mapNorthForeCast.context_more,
      contextShow: true,
      context_index: -1,
    };
  },
  watch: {
    "mapNorthForeCast.data": {
      handler(v) {
        this.$refs.tableForcast.refreshData(v);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    contextS(flag) {
      setTimeout(() => {
        this.contextShow = flag;
      });
    },
    contextFun(item, index) {
      this.context_index = index;
      switch (item.type) {
        case this.context[0].type:
        case this.context[1].type:
        case this.context[2].type:
          this.$toast(item.title + "接入中");
          break;
        case this.context[3].type:
          this.context_more.map((v) => {
            v.checked = false;
            this.handeSwitch(v);
          });
          this.dealTyphoon(3);
          break;
        case this.context[4].type:
          this.dealTyphoon(-1);
          this.contextS(false);
          break;
      }
    },
    controlSwitch(evt) {
      evt.stopPropagation();
    },
    showContext(evt) {
      evt.stopPropagation();
      this.contextS(true);
    },
    handeSwitch(item) {
      const fun = (item) => {
        this.$store.dispatch("mapNorthForeCast/setSelected", {
          checked: item.checked,
          title: item.title,
          image: item.image,
          layerId: item.layerId,
        });
      };
      this.context_more
        .filter((v) => {
          return v.title !== item.title;
        })
        .map((v) => {
          v.checked = false;
          fun(v);
        });
      fun(item);
    },
    // 台风路径
    dealTyphoon(type) {
      this.$store.dispatch("mapTyphoon/setSelected", {
        type: type,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appMenu {
  width: 100%;
  height: 100%;
}
.context {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 60px;
  z-index: 100;

  .contextImage {
    width: 35px;
    height: 35px;
  }
}
.context_more_hidding {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 200;
}
.context_more {
  z-index: 201;
  position: absolute;
  right: 0px;
  top: 0px;
  line-height: 35px;
  background-color: rgba(20, 37, 46, 0.9);
  height: 100%;
  padding: 10px 20px;
  color: white;
  .system-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    line-height: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 0.5rem;
    div {
      margin-right: 10px;
    }
    .img {
      width: 20px;
      height: 20px;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .font {
      width: 50%;
      text-align: left;
      margin-right: 50px;
    }
    .switch {
      text-align: right;
      margin-right: 0.5rem;
    }
  }
}
</style>
