<template>
  <div class="mapFishBoundContainer">
    <map-base ref="mapCom" :map="map" />
    <map-service ref="mapService" :map="map" />
    <Grid ref="grid" :map="map" />
    <MathAnimate ref="mathAnimate" :map="map" />
    <SeaArea ref="seaArea" :map="map" />
    <CitySea ref="citySea" :map="map" />
    <FishBound ref="fishBound" :map="map" />
    <Port ref="port" :map="map" />
    <SeaRanch ref="seaRanch" :map="map" />
    <DataSelect ref="ds" :filterData="filterData" />
  </div>
</template>

<script>
// <script module = 'mapFishBoundContainer' lang="renderjs">
import Grid from "./grid.vue";
import MathAnimate from "./mathAnimate.vue";
import SeaArea from "./seaArea.vue";
import CitySea from "./citySea.vue";
import FishBound from "./fishBound.vue";
import Port from "./port.vue";
import SeaRanch from "./seaRanch.vue";
import DataSelect from "./table/dataSelect.vue";
export default {
  name: "mapNorthForeCast",
  components: {
    Grid,
    MathAnimate,
    SeaArea,
    CitySea,
    FishBound,
    Port,
    SeaRanch,
    DataSelect,
  },
  props: ["map"],
  data() {
    return {
      mapNorthForeCast: this.$store.getters.mapNorthForeCast,
      data: this.$store.getters.mapNorthForeCast.context_more,
      stations: this.$store.getters.mapNorthForeCast.stations,
      filterData: {
        title: "",
        checked: false, // 额外处理的数据（（城市近海预报，网格化预报））之外的类型
        data: null,
        type: "",
        fun: () => {},
      },
    };
  },
  inject: [],
  watch: {
    "mapNorthForeCast.selected": {
      handler(v) {
        // 选中状态
        let checked =
          v.layerId === this.stations[0].layerId &&
          // ||
          //   v.layerId === this.stations[2].layerId
          v.checked;
        // 是否是额外处理类型
        let type = "";
        if (v.layerId === this.stations[0].layerId) type = 0;
        // else if (v.layerId === this.stations[2].layerId) type = 1;
        // 预报类型
        let forecastType = this.stations.find((station) => {
          return v.layerId === station.layerId;
        }).forecastType;
        this.filterData = {
          title: v.title,
          data: v,
          checked: checked,
          forecastType: forecastType,
          type: type,
          fun: (param) => {
            // console.log(666, param);
            this.addData(Object.assign(v, { data: param ? param : null }));
          },
        };
      },
      deep: true,
    },
  },
  onLoad() {},
  mounted() {},
  methods: {
    touchOnly(p1, p2) {
      this.$refs.mapService.touchOnly(p1, p2);
    },
    singleClick(evt) {
      this.$refs.mapService.singleClick(evt);
      this.$refs.grid.singleClick(evt);
      this.$refs.mathAnimate.singleClick(evt);
      this.$refs.seaArea.singleClick(evt);
      this.$refs.citySea.singleClick(evt);
      this.$refs.fishBound.singleClick(evt);
      this.$refs.port.singleClick(evt);
      this.$refs.seaRanch.singleClick(evt);
    },
    addData(data) {
      switch (data.layerId) {
        case this.data[0].layerId:
          if (data.checked) {
            this.$refs.grid.addData(data);
          } else {
            this.$refs.grid.removeLayer();
          }
          break;
        case this.data[1].layerId:
          if (data.checked) {
            this.$refs.mathAnimate.addData(data);
          } else {
            this.$refs.mathAnimate.removeLayer();
          }
          break;
        case this.data[2].layerId:
          if (data.checked) {
            this.$refs.seaArea.addData(data);
          } else {
            this.$refs.seaArea.removeLayer();
          }
          break;
        case this.data[3].layerId:
          if (data.checked) {
            this.$refs.citySea.addData(data);
          } else {
            this.$refs.citySea.removeLayer();
          }
          break;
        case this.data[4].layerId:
          if (data.checked) {
            this.$refs.fishBound.addData(data);
          } else {
            this.$refs.fishBound.removeLayer();
          }
          break;
        case this.data[5].layerId:
          if (data.checked) {
            this.$refs.port.addData(data);
          } else {
            this.$refs.port.removeLayer();
          }
          break;
        case this.data[6].layerId:
          if (data.checked) {
            this.$refs.seaRanch.addData(data);
          } else {
            this.$refs.seaRanch.removeLayer();
          }
          break;
      }
    },
    addSevice(data) {
      if (!data.url) return;
      const servicesData = this.$refs.mapService.getMapUrlData(data.url);
      if (data.checked) {
        this.$refs.mapService.removeServices(servicesData.layerId);
        this.$refs.mapService.getServices(servicesData);
        // if (extent.length === 4)
        //   this.map.getView().fit(this.$refs.mapCom.getExtent(extent, 0.1));
      } else {
        this.$refs.mapService.removeServices(servicesData.layerId);
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
</style>
