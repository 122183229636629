<template>
  <!-- 观测 -->
  <div v-if="filterData.checked">
    <div v-if="filterData.type === 1">
      <CitySelect_Grid
        ref="citySelect_grid"
        :title="filterData.title"
        @gridSelect="gridSelect"
      />
      <div v-show="dataSelectShow">
        <CityCurrent
          ref="cityCurrent"
          :cityCurrentData="cityCurrentData"
          @changePos="changePos"
        />
        <CitySelect
          v-show="citySelectShow"
          ref="citySelect"
          :title="filterData.title"
          :items="items"
          :currentPosRecords="currentPosRecords"
          @close="close"
          @check="check"
        />
      </div>
    </div>
    <div v-if="filterData.type === 0">
      <CityCurrent
        ref="cityCurrent"
        :cityCurrentData="cityCurrentData"
        @changePos="changePos"
      />
      <CitySelect
        v-show="citySelectShow"
        ref="citySelect"
        :title="filterData.title"
        :items="items"
        :currentPosRecords="currentPosRecords"
        @close="close"
        @check="check"
      />
    </div>
  </div>
</template>
<script>
import CitySelect_Grid from "./citySelect_grid.vue"; // 网格化类型选择
import CitySelect from "./citySelect.vue"; // 城市选择列表
import CityCurrent from "./cityCurrent.vue"; // 城市选择标签
import { Overlay, Loading, TreeSelect, Icon } from "vant";
export default {
  components: {
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [TreeSelect.name]: TreeSelect,
    [Icon.name]: Icon,
    CitySelect_Grid,
    CitySelect,
    CityCurrent,
  },
  props: {
    filterData: {
      type: Object.JSON,
      default: {},
    },
  },
  data() {
    return {
      dataSelectShow: false,
      cityCurrentData: {
        name: "",
      },
      citySelectShow: false,
      items: [],
      currentPosRecords: {},
      currentPosRecordsStore:
        this.$store.getters.mapNorthForeCast.currentPosRecords,
    };
  },
  watch: {
    filterData(val) {
      if (!val.checked) {
        val.fun();
      } else {
        this.dataSelectShow = false;
        // this.citySelectShow = true;
        this.citySelectShow = false;
        this.getItems(val);
        this.getCurrentPos(val);
      }
    },
    dataSelectShow(val) {
      if (!val) {
        this.check();
      } else {
        if (this.row && this.row.forecastType === this.filterData.forecastType)
          this.check(this.row);
      }
    },
  },
  mounted() {},
  methods: {
    /**
     * 当前城市选择
     */
    changePos() {
      // console.log("当前城市选择", param);
      this.citySelectShow = true;
    },
    gridSelect(data) {
      this.dataSelectShow = data.gridShow;
      // this.citySelectShow = true;
      // console.log("网格化", data.type);
    },
    /**
     * 获取数据列表
     */
    async getItems(param) {
      let data = await this.$store.dispatch("mapNorthForeCast/getStations", {
        forecastType: param.forecastType,
      });
      if (data && data.success) {
        data = data.result;
        this.items = [];
        let json = {};
        data.map((v) => {
          v.text = v.name;
          if (!json[v.province]) {
            json[v.province] = {};
            this.items.push({
              text: v.province,
              children: [],
            });
          }
          if (!json[v.province][v.city]) {
            json[v.province][v.city] = [];
            this.items
              .find((item) => {
                return item.text === v.province;
              })
              .children.push({
                text: v.city,
                children: [],
              });
          }
          json[v.province][v.city].push(v);
          this.items
            .find((item) => {
              return item.text === v.province;
            })
            .children.find((item) => {
              return item.text === v.city;
            })
            .children.push(v);
        });
        this.items.map((v) => {
          v.data = [];
          v.forecastType = "";
          const fun = (vv) => {
            if (vv.children) {
              vv.children.map((vvv) => {
                vvv.data = [];
                fun(vvv);
              });
            } else {
              if (vv.province === v.text) {
                v.data.push(vv);
                v.forecastType = vv.forecastType;
              }
            }
          };
          fun(v);
        });
      }
    },
    /**
     * 获取最近位置信息
     */
    async getCurrentPos(param) {
      this.currentPosRecordsStore =
        this.$store.getters.mapNorthForeCast.currentPosRecords;
      let exist = this.currentPosRecordsStore.find((v) => {
        return v.forecastType == param.forecastType;
      });
      if (exist) {
        this.currentPosRecords = "";
        setTimeout(() => {
          this.currentPosRecords = exist;
        }, 1000);
        // this.currentPosRecords = exist;
      } else {
        let data = await this.$store.dispatch(
          "mapNorthForeCast/distanceCalculate",
          {
            selectingContentList: [param.title],
          }
        );
        console.log("最近位置信息获取", data);
        if (data && data.success && data.result instanceof Array) {
          data = data.result[0];
          // 获取列表索引值
          this.items.map((v, i) => {
            if (v.text === data.province) {
              data.itemsIndex = i;
              v.children.map((vc, ic) => {
                if (vc.text === data.city) {
                  data.itemsChildIndex = ic;
                }
              });
            }
          });
          // 记录索引值
          this.currentPosRecords = data;
        } else {
          // 获取最新位置失败，则默认第一级
          this.currentPosRecords = Object.assign(param, {
            itemsIndex: 0,
            itemsChildIndex: -1,
          });
        }
        this.setCurrentPosRecordsStore(data);
      }
    },
    // 判断是都有全局存储记录
    setCurrentPosRecordsStore(data) {
      // 全局存储索引值
      if (this.currentPosRecordsStore.length === 0)
        this.currentPosRecordsStore.push(data);
      else {
        let index = -1;
        this.currentPosRecordsStore.map((v, i) => {
          if (v.forecastType == data.forecastType) {
            index = i;
            v = data;
          }
          if (index === -1 && i === this.currentPosRecordsStore.length - 1) {
            this.currentPosRecordsStore.push(data);
          }
        });
      }
    },
    close() {
      this.citySelectShow = false;
    },
    /**
     * type: 0--省；1--市；2--数据
     */
    check(row) {
      this.$store.dispatch("mapNorthForeCast/getData", {
        checked: false,
      });
      // console.log("check", row);
      if (row) {
        this.currentPosRecordsStore.map((v) => {
          if (v.forecastType == this.filterData.forecastType)
            v = Object.assign(v, row);
        });
        if (this.filterData.checked) {
          this.row = Object.assign(row, {
            forecastType: this.filterData.forecastType,
          });
        }
        if (row.children) {
          if (row.children[0].children) {
            let array = [];
            row.children.map((v) => {
              v.children.map((cv) => {
                array.push(cv);
              });
            });
            this.filterData.fun(array);
          } else {
            this.filterData.fun(row.children);
          }
        } else this.filterData.fun([row]);
        this.cityCurrentData = {
          name: row.text,
        };
      } else {
        this.filterData.fun([]);
        this.cityCurrentData = {
          name: "暂无",
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
